// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.status-check {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    justify-content: flex-start;
}

.status-check span.status-icon {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.status-check.success {
    color: #0BB68C;
}

.status-check.success span.status-icon {
    background-color: #0BB68C;
}

.status-check.uncomplete {
    color: #FBAC35;
}

.status-check.uncomplete span.status-icon {
    background-color: #FBAC35;
}

.status-check.failed {
    color: #FD4B6B;
}

.status-check.failed span.status-icon {
    background-color: #FD4B6B;
}

.email-status {
    display: flex;
    align-items: center;
    gap: 10px;
}

.email-status .status-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.active .email-status .status-dot {
    background: #0BB68C;
}

.inactive .email-status .status-dot {
    background: #FBAC35;
}

.failed .email-status .status-dot {
    background: #FD4B6B;
}`, "",{"version":3,"sources":["webpack://./src/components/user-status/user-status.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,gBAAgB;IAChB,2BAA2B;AAC/B;;AAEA;IACI,UAAU;IACV,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,UAAU;IACV,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["\n\n.status-check {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    font-weight: 500;\n    justify-content: flex-start;\n}\n\n.status-check span.status-icon {\n    width: 8px;\n    height: 8px;\n    border-radius: 50%;\n}\n\n.status-check.success {\n    color: #0BB68C;\n}\n\n.status-check.success span.status-icon {\n    background-color: #0BB68C;\n}\n\n.status-check.uncomplete {\n    color: #FBAC35;\n}\n\n.status-check.uncomplete span.status-icon {\n    background-color: #FBAC35;\n}\n\n.status-check.failed {\n    color: #FD4B6B;\n}\n\n.status-check.failed span.status-icon {\n    background-color: #FD4B6B;\n}\n\n.email-status {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n}\n\n.email-status .status-dot {\n    width: 8px;\n    height: 8px;\n    border-radius: 50%;\n}\n\n.active .email-status .status-dot {\n    background: #0BB68C;\n}\n\n.inactive .email-status .status-dot {\n    background: #FBAC35;\n}\n\n.failed .email-status .status-dot {\n    background: #FD4B6B;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
