import React, { useState,useEffect,useContext } from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import PageTitle from "../page-title/page-title";
import Input from "../input/input";
import Inputpassword from "../input/input-password";
import Exs_logo from "../../images/account/exs-logo.png";

import { ArrowLeft } from 'react-bootstrap-icons';
import { getUserDetails } from '../../services/admin';
import UserContext from '../../store/user-context';

import './settings.css'

import { updateUserInfo } from '../../services/admin';


const GeneralSettings = ({setSelectedSettings}) => {

  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage,setSuccessMessage]=useState('');
  const [userData,setUserData]=useState({})


  const { userState:{userId,superAdmin}, setUserState } = useContext(UserContext)

  useEffect(() => {
    const fetchData = async () => {     
        try {
            const resp = await getUserDetails(userId)
            console.log(resp)
            if (resp.success) {
                console.log(resp.data)
                setName(resp.data.name)
                setEmail(resp.data.email)
                {superAdmin=="false" && setCompany(resp.data.companyName)}
                setUserData(resp.data)
            }
            else
                throw new Error(resp.error)
        } catch (error) {
            console.log(error)
        }
    }

        fetchData()
}, [userId])


  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleCompanyChange = (event) => {
      setCompany(event.target.value);
  };

  const handleEmailChange = (event) => {
      const enteredEmail = event.target.value;
      setEmail(enteredEmail);
      validateEmail(enteredEmail);
  };

  const handlePasswordChange = (event) => {
      setPassword(event.target.value);
      validatePasswords(event.target.value);
  };

  const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
              setErrorMessage('Invalid email format');
              
          } else {
              setErrorMessage('');
          }
  };

  const validatePasswords = (password) => {
      if (password.length===0) {
          setErrorMessage("Password cannot be empty");
      } else {
          setErrorMessage("");
      }
  };

  const isFormValid =  
        name !== '' &&  
        (superAdmin!="false" ? company === '' : company !== '') && 
        email !== '' && 
        password !== '' && 
        errorMessage === '' &&
        (name!==userData.name ||
        email!==userData.email ||
        (superAdmin=="false" && company!==userData.companyName)); 


  const handleSubmit=async ()=>{
    const formData = {
      userId: userId,
      name: name,
      email: email,
      ...(superAdmin!="false" ? {} : { company: company }),
      password: password
    };

    const resp=await updateUserInfo(formData)
    console.log(resp)
    if(resp.success){
      setSuccessMessage(resp.message)
      setUserState(prevState => ({
        ...prevState,
        updateUser:true
    }));
    setTimeout(() => {
      setSelectedSettings(null)
    }, 1000);
    }
    else{
      setErrorMessage(resp.message)
    }

  }


  return (
    <div className = "account-form-side sigup-login-form h-100">
        <div className = "form-group mb-4">
        <div className="d-flex align-items-center">
            <ArrowLeft className='arrow-left' onClick={()=>{setSelectedSettings(null)}}/>
            <div className = "page-title1">
              Personal Information
            </div>
        </div>
        </div>
        <Input type = {'text'} value = {'name'} val={name} label = 'Name' onChange={handleNameChange}/>
        <Input type = {'email'} value = {'email'} val={email} label = 'Email' onChange={handleEmailChange}/>
        {superAdmin=="false" && <Input type = {'text'} value = {'company'} val={company} label = 'Company Name' onChange={handleCompanyChange} />}
        <Inputpassword type = 'password' value = {'password'} label = 'Password' onChange={handlePasswordChange} />

        {errorMessage && <p className="error-message mb-2 text-danger">{errorMessage}</p>}
        {successMessage && <p className="error-message mb-2 text-success">{successMessage}</p>}

        <div className = "form-group">
            <Button type = "submit" variant = "primary" disabled={!isFormValid} onClick={handleSubmit}>
                Save Change
            </Button>
        </div>
    </div>
  )
}

export default GeneralSettings