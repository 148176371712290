import React, { useState,useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import { Link,useNavigate } from 'react-router-dom';

import { loginUser } from '../services/admin';
import UserContext from '../store/user-context';
import Input from '../components/input/input';
import Inputpassword from '../components/input/input-password';

const Login = ()=>{

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate=useNavigate()
    const { userState, setUserState } = useContext(UserContext)

    const handleEmailChange = (event) => {
        const enteredEmail = event.target.value;
        setEmail(enteredEmail);
        validateEmail(enteredEmail);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        if(errorMessage==="Wrong password")
            setErrorMessage('')
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
                setErrorMessage('Invalid email format');
            } else {
                setErrorMessage('');
            }
    };

    const handleLogin = async () => {
        try{
          const resp = await loginUser({email,password})
          console.log(resp)
          if(resp.success){
            console.log("Login successful")
            setEmail('')
            setPassword('')
            localStorage.setItem('token', resp.token)
            localStorage.setItem('userId', resp.userId)
            localStorage.setItem('superAdmin', resp.superadmin)
            localStorage.setItem('companyAdmin', resp.companyadmin)
            if(resp.companyid)
              localStorage.setItem('companyId', resp.companyid)
            console.log("setting up local storage ",resp.userId," ",resp.superadmin, resp.companyadmin, resp.companyid)
            const remainingMilliseconds = 60 * 60 * 1000;
            const expiryDate = new Date(
                new Date().getTime() + remainingMilliseconds
            );
            localStorage.setItem('expiryDate', expiryDate.toISOString());
            setUserState({ isAuth: true, token: resp.token, userId: resp.userId, superAdmin: resp.superadmin, companyAdmin: resp.companyadmin, companyId: resp.companyid })
            // console.log("response: ",resp.isadmin)
            navigate('/')
          }
          else{
            console.log(resp.message)
            setErrorMessage(resp.message)
          }
        }
        catch(e){
          console.log(e)
        }
      };

    const isFormValid = email !== '' && 
    password !== '' &&
    errorMessage === '';

    return(

        <section className = "main form-main">
            <Container>
                <Row>
                    <Col xs = "12">

                    <div className = "signup-content position-relative d-flex align-items-center justify-content-center">
                            <div className = "sigup-login-form login-form">
                                <h1>
                                    Login in to your account
                                </h1>

                                <Input type = {'email'} value = {'email@example.com'} label = {'Email'} onChange={handleEmailChange}/>

                               <Inputpassword type = {'password'} value = {'Password'} label = {'Password'} onChange={handlePasswordChange}/>

                               {errorMessage && <p className="error-message mb-2 text-danger">{errorMessage}</p>}

                                <div className = "form-group">
                                    <p className = "text-center">
                                        <Link to = "/forgot-password">Forgot your password?</Link>
                                    </p>
                                </div>

                                <div className = "form-group">
                                    <Button type = "submit" variant = "primary" onClick={handleLogin} disabled={!isFormValid}>
                                        Login
                                    </Button>
                                </div>

                                <div className = "form-group">
                                    <p className = "mb-0 text-center f-pra">
                                        Don't have an account <Link to = "/">Sign up</Link>
                                    </p>
                                </div>

                            </div>
                    </div>

                    </Col>
                </Row>
            </Container>
        </section>
        
    );
}

export default Login;
