
import React from 'react';

import "./input.css";

const Input = ({label,type,value,val='', onChange,autoComplete='on'}) => {
    return(
        <>
            <div className = "form-group form-floating">
                { val.length>0 ?
                <input type = {type} className = "form-control" value={val} placeholder = {value} onChange={onChange} autoComplete={autoComplete}/>
                :
                <input type = {type} className = "form-control" placeholder = {value} onChange={onChange} autoComplete={autoComplete}/>}
                <label>{label}</label>
            </div>
        </>
    );
}

export default Input;