import React from 'react';
import { Table } from 'react-bootstrap';
import { ArrowRight, Key, Person } from 'react-bootstrap-icons';

import './settings.css'

const SelectSettings = ({setSelectedSettings}) => {

  return (
    <div className="account-form-side sigup-login-form h-100">
      <div className="form-group mb-4">
        <h2 className="page-title">Settings</h2>
      </div>
      <table>
        <tbody>
          <tr className="settings-table-row" onClick={()=>{setSelectedSettings("general")}}>
            <td className="settings-icon"><Person /></td>
            <td className="settings-option">General Info</td>
            <td className="settings-arrow"><ArrowRight /></td>
          </tr>
          <tr className="settings-table-row" onClick={()=>{setSelectedSettings("password")}}>
            <td className="settings-icon"><Key /></td>
            <td className="settings-option">Password Management</td>
            <td className="settings-arrow"><ArrowRight /></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SelectSettings;