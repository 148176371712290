import React,{ Fragment } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import {Button,Container} from "react-bootstrap"

import UserContext from './store/user-context'
import CompanyDashboard from "./pages/company-dashboard";
import Login from "./pages/login"
import Signup from "./pages/signup"
import Header from "./components/navbar/Navbar";
import Forgotpassword from "./pages/forgot-password";
import Resetpassword from "./pages/reset-password";
import MyAccount from "./pages/my-account";
import ManageApi from "./pages/manage-api";
import ManageCompany from "./pages/manage-company";


function Router(){
    const { userState: { isAuth,superAdmin,companyAdmin,companyId }, setUserState } = useContext(UserContext)
    const [isLoaded, setIsLoaded] = useState(false)
    const navigate = useNavigate();

    const logOutBtnHandler = (navigateTo="/login") => {
        localStorage.removeItem('token')
        localStorage.removeItem('userId')
        setUserState({ isAuth: false, token: null,email: null, userId: null, superAdmin: false, companyAdmin: false, companyId: null, updateUser:false })
        navigate(navigateTo)
    }
    useEffect(() => {
        setIsLoaded(false)
        const token = localStorage.getItem('token')
        const userId = localStorage.getItem('userId')
        const superadmin = localStorage.getItem('superAdmin')
        const companyadmin = localStorage.getItem('superAdmin')
        const companyid = localStorage.getItem('companyId')
        console.log(token)
        console.log(userId)
        if (!token || !userId) {
            setIsLoaded(true)
            return
        }
        setUserState({ isAuth: true, token: token, userId: userId , superAdmin: superadmin, companyAdmin: companyadmin, companyId: companyid, updateUser:false})
        setIsLoaded(true)
    }, [setUserState])

    console.log(isAuth)

    return(
      <Fragment>
        <Header logOutBtnHandler={logOutBtnHandler}/> 
            {isLoaded?
                <Routes>
                    {
                        isAuth?
                            localStorage.getItem('superAdmin')=="true"?
                                <Route>
                                    <Route path='/' element={<ManageCompany  />}/>
                                    <Route path='/dashboard' element={<ManageCompany  />}/>
                                    <Route path= "/my-account" element={<MyAccount/>}/>
                                </Route>
                            :
                                <Route>
                                    <Route path='/' element={<CompanyDashboard  />}/>
                                    <Route path='/dashboard' element={<CompanyDashboard  />}/>
                                    <Route path= "/my-account" element={<MyAccount/>}/>
                                    <Route path= "/manage-api" element={<ManageApi/>}/>
                                </Route>
                        :
                            <Route>
                                <Route path='/' element={<Signup  />}/>
                                <Route path='/login' element={<Login  />}/>
                                <Route path='/signup' element={<Signup />}/>
                                <Route path='/forgot-password' element={<Forgotpassword/>}/>
                                <Route path= "/reset-password" element={<Resetpassword/>}/>
                            </Route>
                        

                    }
                    <Route
                        path='*'
                        element={
                            <div className='heading' style={{marginTop:'150px'}}>
                                <Container className='d-flex flex-column '>
                                    <h1>There's nothing here. You've taken a wrong turn!</h1>
                                    <Button className='w-25' style={{ fontSize: '1.5rem' }} onClick={() => navigate(-1)}>Go Back</Button>
                                </Container>
                            </div>
                        }
                    />

                </Routes>
                :
                <>
                </>
            }
        </Fragment>
    )
}

export default Router