// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.settings-table-row {
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 60px; /* Adjust the height as desired */
}

.settings-table-row:hover {
  background-color: #f5f5f5;
}

.settings-icon {
  padding: 10px;
  font-size: 24px; /* Adjust the icon size as desired */
  line-height: 5%;
}

.settings-option {
  padding: 10px;
  font-size: 18px; /* Adjust the font size as desired */
}

.settings-arrow {
  padding: 10px;
  font-size: 24px; /* Adjust the arrow size as desired */
  line-height: 10%;
}

.arrow-left{
    margin-right: 10px;
    border: 1px solid black;
    border-radius: 50%;
    font-size: 30px;
    padding: 4px;
    cursor: pointer;
    color: #464B53;
    min-width: 30px;
}

.page-title1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #464B53;
}

.password-title{
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/my-account/settings.css"],"names":[],"mappings":";;AAEA;EACE,eAAe;EACf,sCAAsC;EACtC,YAAY,EAAE,iCAAiC;AACjD;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,eAAe,EAAE,oCAAoC;EACrD,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,eAAe,EAAE,oCAAoC;AACvD;;AAEA;EACE,aAAa;EACb,eAAe,EAAE,qCAAqC;EACtD,gBAAgB;AAClB;;AAEA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,eAAe;IACf,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":["\n\n.settings-table-row {\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n  height: 60px; /* Adjust the height as desired */\n}\n\n.settings-table-row:hover {\n  background-color: #f5f5f5;\n}\n\n.settings-icon {\n  padding: 10px;\n  font-size: 24px; /* Adjust the icon size as desired */\n  line-height: 5%;\n}\n\n.settings-option {\n  padding: 10px;\n  font-size: 18px; /* Adjust the font size as desired */\n}\n\n.settings-arrow {\n  padding: 10px;\n  font-size: 24px; /* Adjust the arrow size as desired */\n  line-height: 10%;\n}\n\n.arrow-left{\n    margin-right: 10px;\n    border: 1px solid black;\n    border-radius: 50%;\n    font-size: 30px;\n    padding: 4px;\n    cursor: pointer;\n    color: #464B53;\n    min-width: 30px;\n}\n\n.page-title1 {\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 23px;\n    color: #464B53;\n}\n\n.password-title{\n    font-weight: 600;\n    font-size: 15px;\n    line-height: 23px;\n    margin-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
