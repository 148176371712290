// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.admin-section .btn-primary {
    padding: 14px 40px;
}

.admin-section .page-title {
    margin-bottom: 0px;
}

.admin-section .kyc-table {
    padding-top: 20px;
}

.btn-renew-package {
    border: 1px solid #4080FF;
    border-radius: 4px; 
    padding: 12px 22px;
    color: #4080FF;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
}

.btn-renew-package:hover {
    color: #fff;
    background: #4080FF;
}`, "",{"version":3,"sources":["webpack://./src/css/kyc-admin-style.css"],"names":[],"mappings":";;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB","sourcesContent":["\n\n.admin-section .btn-primary {\n    padding: 14px 40px;\n}\n\n.admin-section .page-title {\n    margin-bottom: 0px;\n}\n\n.admin-section .kyc-table {\n    padding-top: 20px;\n}\n\n.btn-renew-package {\n    border: 1px solid #4080FF;\n    border-radius: 4px; \n    padding: 12px 22px;\n    color: #4080FF;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 16px;\n    letter-spacing: 0.01em;\n}\n\n.btn-renew-package:hover {\n    color: #fff;\n    background: #4080FF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
