
import React from "react";

import "./user-details.css";

const UserPhoto = ({photo}) => {
    return (
        <>
            <div className = "user-photo">
                <img src = {photo} alt = "user"/>
            </div>
        </>
    )
}

export default UserPhoto;