import React, { useEffect, useState } from "react";
import { Modal,Form,Button } from 'react-bootstrap'

import StatusSelect from "../admin-component/status-dropdown1";
import { editKycStatus } from "../../services/companyadmin";


const EditStatus = ({show,handleClose,kycDetails,updateScreen}) => {

    console.log(kycDetails)

    const [selectedOption,setSelectedOption]=useState(null)
    const [comments,setComments]=useState('')
    const [successMessage,setSuccessMessage]=useState('')

    const handleSelect=(option)=>{
        setSelectedOption(option)
    }

    const handleClose1=()=>{
        setComments('')
        setSuccessMessage('')
        setSelectedOption(null)
        handleClose()
    }



    useEffect(()=>{
        if(kycDetails.kycMessage && comments.length===0)
            setComments(kycDetails.kycMessage)
        if(kycDetails._id && selectedOption===null)
            setSelectedOption(!kycDetails.kycCompleted? {label:'Incomplete',value:'incomplete'}: kycDetails.kycStatus?{label:'Successful',value:'success'}:{label:'Failed',value:'failed'})
    },[kycDetails,show])

    console.log(selectedOption)

    const options = [
        { value: 'success', label: 'Successful' },
        { value: 'failed', label: 'Failed' },
        { value: 'incomplete', label: 'Incomplete' },
      ];

      const handleSubmit=async ()=>{
        try{
            const resp=await editKycStatus({id:kycDetails._id,status:selectedOption.value,comments:comments})
            console.log(resp)
            if(resp.success){
                setSuccessMessage(resp.message)
                setTimeout(() => {
                    updateScreen()
                    handleClose1()
                }, 1500);
            }
        }
        catch(e){
            console.log(e)
        }
      }
    


  return (
        <Modal show={show} onHide={handleClose1} centered className = "create-api-modal">
            <Modal.Header closeButton>
                <Modal.Title>Edit KYC Status</Modal.Title>
            </Modal.Header>
                <Modal.Body style={{background:'#EFF3FC',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px'}}>
                    <div style={{marginBottom:'25px',marginLeft:'12px'}}>
                        <h5 style={{fontSize:'18px',marginBottom:'10px'}}>KYC Status</h5>
                        <StatusSelect options={options} selected={!kycDetails.kycCompleted? {label:'Incomplete',value:'incomplete'}: kycDetails.kycStatus?{label:'Successful',value:'success'}:{label:'Failed',value:'failed'}} onSelect={handleSelect} label="Status - Active" />
                    </div>
                    <div style={{marginBottom:'5px',marginLeft:'12px'}}>
                        <h5 style={{fontSize:'18px',marginBottom:'10px'}}>KYC Comments</h5>
                        <textarea onChange={(e)=>{setComments(e.target.value)}} value={comments} style={{width:'350px',height:'100px'}}/>
                    </div>
                    {successMessage && <p style={{marginLeft:'12px',marginBottom:'10px'}} className="error-message mb-2 text-success">{successMessage}</p>}
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <Button className="btn btn-success-outline" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>
                </Modal.Body>
        </Modal>
  )
}

export default EditStatus