
import React from 'react';

import "./report-card.css";


const ReportCard = ({Image, reportTitle, result}) => {
    return (
        <>
            <div className = "report-area d-flex align-items-center">
                <div className = "status-icon">
                    {Image}
                </div>
                <div className = "status-content">
                    <div className = "status-title">
                        {reportTitle}
                    </div>
                    <div className = "status-result">
                        {result}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportCard;