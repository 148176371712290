import axios from 'axios'
import config from '../config'

const baseUrl = `${config.backend}company/`

export const getAllCompanyKycs = async (companyId, filteredConfig, fromDate, toDate) => {
  const params = {
    _id: companyId,
    configId: filteredConfig,
    fromDate: fromDate,
    toDate: toDate
  };

  const response = await axios.get(`${baseUrl}company-kycs`, { params });
  return response.data;
};


export const getKycDetails = async(data) => {
    const response = await axios.get(`${baseUrl}getdetails?kycId=${data}`)
    return response.data
  }

  export const getKycCount = async(companyId,filteredConfig) => {
    const response = await axios.get(`${baseUrl}getstats?_id=${companyId}&configId=${filteredConfig}`)
    return response.data
  }

  export const generateAccessKey = async (data) => {
    const response = await axios.post(`${baseUrl}generatekey`, data)
    return response.data
}

export const getConfig = async(data) => {
  const response = await axios.get(`${baseUrl}getallconfig?_id=${data}`)
  return response.data
}

export const getAllConfig = async(data) => {
  const response = await axios.get(`${baseUrl}getconfigdetails?_id=${data}`)
  return response.data
}

export const createConfiguration = async (data) => {
  const response = await axios.post(`${baseUrl}createnewconfig`, data)
  return response.data
}

export const deleteConfiguration = async (data) => {
  const response = await axios.post(`${baseUrl}deleteconfig`, data)
  return response.data
}

export const editKycStatus = async (data) => {
  const response = await axios.post(`${baseUrl}editstatus`, data)
  return response.data
}