
import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import {Link,useNavigate} from "react-router-dom";

import Input from '../components/input/input';
import Inputpassword from '../components/input/input-password';
import { registerUser } from '../services/admin';

const Signup = ()=>{

    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatpassword, setRepeatpassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage,setSuccessMessage]=useState('')
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        companyName: '',
        password: '',
        confirmPassword: '',
        });

    const navigate = useNavigate()

    const handleNameChange = (event) => {
        setName(event.target.value);
        handleInputChange("name",event.target.value)
    };

    const handleCompanyChange = (event) => {
        setCompany(event.target.value);
        handleInputChange("companyName",event.target.value)
        
    };

    const handleEmailChange = (event) => {
        const enteredEmail = event.target.value;
        setEmail(enteredEmail);
        validateEmail(enteredEmail);
        handleInputChange("email",event.target.value)
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        validatePasswords(event.target.value, repeatpassword);
        handleInputChange("password",event.target.value)
    };

    const handleRepeatpasswordChange = (event) => {
        setRepeatpassword(event.target.value);
        validatePasswords(password, event.target.value);
        handleInputChange("confirmPassword",event.target.value)
    }

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
                setErrorMessage('Invalid email format');
                
            } else {
                setErrorMessage('');
            }
    };

    const validatePasswords = (password, repeatPassword) => {
        if (password !== repeatPassword) {
            setErrorMessage("Passwords do not match");
        } else {
            setErrorMessage("");
        }
    };

    

    const isFormValid =  
        name !== '' &&  
        company !== '' && 
        email !== '' && 
        password !== '' && 
        repeatpassword !== '' && 
        password === repeatpassword &&
        errorMessage === ''; 

    const handleInputChange = (name,value) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };

    const clearInputValues = () => {
        setFormData({ 
            name: '',
            email: '',
            companyName: '',
            password: '',
            confirmPassword: ''})
        setName('')
        setCompany('')
        setEmail('')
        setPassword('')
        setRepeatpassword('')
    }

    const handleSignup = async () => {
        try
        {
            if(formData.password===formData.confirmPassword)
            {
                const resp = await registerUser(formData)
                if(resp.success){
                    clearInputValues()
                    console.log("successful")
                    setSuccessMessage("Company Registered Successfully ✅")
                    console.log(resp)
                    setTimeout(() => {
                        navigate('/login')
                    }, 1000);
                }
                else{
                    console.log(resp)
                    setErrorMessage(resp.error)
                }
            }
            else{
                setErrorMessage("passwords dont match")
            }
        }
        catch(e){
            console.log(e)
        }
      };
    
    console.log(formData)

    return(

        <section className = "main form-main">
            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "signup-content position-relative d-lg-flex align-items-center justify-content-end">
                            <div className = "signup-title">
                                <h1>
                                    Discover the KYC platform for global customer onboarding
                                </h1>
                                <p>
                                    Reduce verification costs by 46%
                                </p>
                                <p>
                                    Increase onboarding rate by 26% with minimum false positives
                                </p>
                                <p>
                                    Accessible in 230+ countries with support of 250+ document types
                                </p>
                            </div>
                            <div className = "sigup-login-form register-form">
                                <h1>
                                    Register Your Company
                                </h1>
                                <Input type = {'text'} value = {'name'} label = 'Name' onChange={handleNameChange} autoComplete='nope'/>

                                <Input type = {'email'} value = {'email'} label = 'Email' onChange={handleEmailChange} autoComplete='nope'/>

                                <Input type = {'text'} value = {'company'} label = 'Company Name' onChange={handleCompanyChange} autoComplete='nope'/>

                                <Inputpassword type = 'password' value = {'password'} label = 'Password' onChange={handlePasswordChange} autoComplete='new-password'/>

                                <Inputpassword type = 'password' value = {'repeatpassword'} label = 'Repeat Password' onChange={handleRepeatpasswordChange} autoComplete='nope'/>

                                {errorMessage && <p className="error-message mb-2 text-danger">{errorMessage}</p>}
                                {successMessage && <p className="error-message mb-2 text-success">{successMessage}</p>}

                                <div className = "form-group">
                                    <p className = "mb-0">
                                        By submitting this form, you consent to allow us to store and process the personal information submitted above to provide you the content requested. Please review our <Link to = "#">Privacy Policy</Link>
                                    </p>
                                </div>

                                <div className = "form-group">
                                    <Button onClick={handleSignup} type = "submit" variant = "primary" disabled={!isFormValid}>
                                        Register
                                    </Button>
                                </div>

                                <div className = "form-group">
                                    <p className = "mb-0 text-center f-pra">
                                        <Link to = "/login">Sign in</Link>  If you already have an account
                                    </p>
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        
    );
}

export default Signup;
