
import React, { useState } from "react"; 

import "./access-key.css";

const Accesskey = ({activationKey}) => {
    console.log(activationKey)

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const togglepassword = () => {
        setIsActive(!isActive);
    }

    const handleClick = () => {
        togglepassword();
        togglePasswordVisibility();
    }

    return (
        <div className = "access-key">
            <div className = "key-text">
                {passwordVisible ? activationKey : '**********************'}
            </div>
            <div className = {isActive ? 'active show-btn' : 'show-btn'} onClick={handleClick}></div>
        </div>
    )
}

export default Accesskey;