
import React from "react";

import "./user-details.css";

const UserDetails = ({title, titleDescription}) => {
    return (
        <>
            <div className = "info-row">
                <div className = "title">
                    {title}
                </div>
                <div className = "description"> {titleDescription} </div>
            </div>
        </>
    )
}

export default UserDetails;