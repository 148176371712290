import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Collapse from 'react-bootstrap/Collapse';
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import RepotCard from "../kyc-report/report-card";
import StatusComp from "../user-status/user-status";
import StatusSelect from "./status-dropdown";
import EmailStatus from "../user-status/email-status";
import detailBg from "../../images/dashboard/detail-db-icon.svg";
import Success from '../../images/dashboard/KYC-success.svg';
import Uncompleted from '../../images/dashboard/KYC-uncompleted.svg';
import Failed from '../../images/dashboard/KYC-failed.svg';
import {Spinner} from "react-bootstrap";

import { deleteUser,reverseDeleteUser,editApproval,updatePackage } from "../../services/superadmin";

const CompanyTable = ({ data, getCompanyData,setLoading, loading,updateScreen,itemsPerPage,pageNumber }) => {


  const [expandedRow, setExpandedRow] = useState(null);
  const [companyDetails, setCompanyDetails] = useState({});
  const [companyToShow, setCompanyToShow] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if(!loading && data.length===0)
    {const timeout = setTimeout(() => {
      setShowMessage(true);
    }, 500);

    return () => clearTimeout(timeout); }// Cleanup function to clear the timeout on unmount
  }, [loading,data]);


  function convertDate(dateString) {
    const date = new Date(dateString);
  
    // Format the date
    const formattedDate = date.toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  
    const [month, day, year] = formattedDate.split(' ');
  
    return `${day.slice(0,-1)} ${month.toUpperCase()} ${year}`;
  }

  const toggleCollapse = async (rowIndex, id) => {
    if (expandedRow === rowIndex) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowIndex);
      setCompanyToShow(id);
    }
  };

  const getData = async () => {
    try {
      const resp = await getCompanyData(companyToShow);
      setCompanyDetails(resp);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    

    if (companyToShow.length > 0)
      getData();
  }, [companyToShow])

  const options = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
    { value: 'expired', label: 'Expired' },
  ];

  const handleSelect = async (option) => {
    console.log('Selected option:', option);
    console.log('Selected Company:', companyToShow);
  
    const company = data.find((item) => item.companyId === companyToShow);
    
    if((option.value==="expired" && company.isdeleted) || (option.value==="inactive" && !company.isdeleted && !company.isapproved) || (option.value==="active" && !company.isdeleted && company.isapproved)){
      console.log("returned")
      return
    }

    if(option.value==="expired"){
      const resp= await deleteUser(company._id)
      console.log(resp)
      if(resp.success){
        updateScreen()
      }
    }
    if(option.value==="inactive"){
      if(company.isdeleted){
        const resp=await reverseDeleteUser(company._id)
        console.log(resp)
        if(resp.success){
          if(company.isapproved){
            const resp1=await editApproval(company._id)
            if(resp1.success){
              updateScreen()
            }
          }
          else
            updateScreen()
        }
      }
      else{
        const resp=await editApproval(company._id)
        console.log(resp)
        if(resp.success){
          updateScreen()
        }
      }
    }
    if(option.value==="active"){
      console.log("active")
      if(company.isdeleted){
        const resp=await reverseDeleteUser(company._id)
        console.log(resp)
        if(resp.success){
          console.log('succcess')
          if(!company.isapproved){
            const resp1=await editApproval(company._id)
            if(resp1.success){
              updateScreen()
            }
          }
          else
            updateScreen()
        }
      }
      else{
        const resp=await editApproval(company._id)
        console.log(resp)
        if(resp.success){
          updateScreen()
        }
      }
    }

  
  };

  const packageUpdate = async()=>{
    try{
      const resp=await updatePackage(companyToShow)
      console.log(resp)
      if(resp.success){
        getData()
        updateScreen()
      }
    }
    catch(e){
      console.log(e)
    }
  }



  return (
    <div className="kyc-custom-table">
      <Table responsive className="kyc-data-table mb-0">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Created AT</th>
            <th>License Expiry</th>
            <th>KYC USAGE</th>
            <th>Status</th>
            <th>Details</th>
          </tr>
        </thead>
        {loading ? (
        <tbody>
          <tr style={{ textAlign: 'center', margin: '50px' }}>
            <td style={{ fontSize: '15px', color: '#A1ABC5' }} colSpan={12}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            </td>
          </tr>
        </tbody>
        ) :(data.length > 0) ? (
            <tbody>
          {data.map((item, index) => (
            <React.Fragment key={item.companyId}>
              <tr
                className={expandedRow === index ? 'active' : ''}
                onClick={() => toggleCollapse(index, item.companyId)}
                aria-controls={`detail${index}`}
                aria-expanded={expandedRow === index}
              >
                <td>{ index<10 & 0 }{((pageNumber-1)*itemsPerPage)+ (index + 1) }</td>
                <td>
                  <EmailStatus className={item.isdeleted? "failed": item.isapproved? "active": "inactive"} email={item.companyName} />
                </td>
                <td>{item.email}</td>
                <td>{convertDate(item.createdAt)}</td>
                <td>{convertDate(item.packageExpiry)}</td>
                <td>{`${item.totalKyc-item.kycLeft}/${item.totalKyc} `}</td>
                <td>
                  <div className="status-check success">
                    <StatusComp admin={true} status={item.isdeleted? "failed": item.isapproved ? 'success' : 'uncomplete'} />
                  </div>
                </td>
                <td>
                  <div className="detail-btn">
                    <img src={detailBg} alt="db-icon" />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="hidden-row py-0" colSpan={8}>
                  <Collapse in={expandedRow === index}>
                    <div id={`detail${index}`}>
                      <Row className="py-3">
                        <Col xs={12}>
                          <div className="kyc-report manage-company-report">
                            <Row className="mx-0 justify-content-between align-items-center">
                            {companyDetails.kycCounts ? (
                            <>
                              <Col>
                                <RepotCard
                                  Image={
                                    <svg className="pie-svg" height="35" width="35" viewBox="0 0 20 20">
                                      <circle r="10" cx="10" cy="10" fill="white" />
                                      <circle
                                        r="5"
                                        cx="10"
                                        cy="10"
                                        fill="transparent"
                                        stroke="#4080FF"
                                        strokeWidth="10"
                                        strokeDasharray="calc(35 * 31.4 / 100) 31.4"
                                        transform="rotate(-90) translate(-20)"
                                      />
                                    </svg>
                                  }
                                  reportTitle="KYC Consumed"
                                  result={`${companyDetails.kycCounts.attempted} / ${companyDetails.kycCounts.total}`}
                                />
                              </Col>
                              <Col>
                                <RepotCard
                                  Image={<img src={Success} alt="icon" />}
                                  reportTitle="KYC Successful"
                                  result={companyDetails.kycCounts.success}
                                />
                              </Col>
                              <Col>
                                <RepotCard
                                  Image={<img src={Uncompleted} alt="icon" />}
                                  reportTitle="KYC Uncompleted"
                                  result={companyDetails.kycCounts.attempted-companyDetails.kycCounts.complete}
                                />
                              </Col>
                              <Col>
                                <RepotCard
                                  Image={<img src={Failed} alt="icon" />}
                                  reportTitle="KYC Failed"
                                  result={companyDetails.kycCounts.failed}
                                />
                              </Col>
                              <Col>
                                <div className="status-control py-3">
                                  <StatusSelect options={options} selected={item.isdeleted? {label:'Expired',value:'expired'}: item.isapproved?{label:'Active',value:'active'}:{label:'Inactive',value:'inactive'}} onSelect={handleSelect} label="Status - Active" />
                                  <Button variant="renew-package" onClick={packageUpdate}>Renew Package</Button>
                                </div>
                              </Col>
                              </>)
                              : (
                                <Col>
                                  <p>Loading...</p>
                                </Col>
                              )}
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>)
        :(
          showMessage? 
            <tbody>
                <tr style={{textAlign:'center',margin:'50px'}}>
                    <td style={{fontSize:'30px',color:'black',fontWeight:'300'}} colSpan={12}>No Results Found</td>
                </tr>
            </tbody>:
            <tbody>
            <tr style={{ textAlign: 'center', margin: '50px' }}>
            <td style={{ fontSize: '15px', color: '#A1ABC5' }} colSpan={12}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            </td>
          </tr>
          </tbody>
      )}
      </Table>
    </div>
  );
}

export default CompanyTable;
