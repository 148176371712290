import React, { useState, useRef, useEffect } from 'react';

import "./selectstatus.css";
import dropdownIcon from "./Vector.svg";
import dropdownIcon_inactive from "./vactor-inactive.svg";
import dropdownIcon_expired from "./vector-expired.svg"

const StatusSelect = ({ options,selected, onSelect, label }) => {
  const [selectedOption, setSelectedOption] = useState(selected);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const [color,setColor]=useState('#0BB68C')

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option)
    onSelect(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(()=>{
    if(selectedOption.value==="expired")
      setColor('red')
    else if(selectedOption.value==="active")
    setColor('#0BB68C')
    else
      setColor('#FBAC35')

  },[selectedOption])

  return (
    <div ref={selectRef} className={`custom-select custom-select1 status-select mb-2 ${isOpen ? 'open' : ''}`}>
      <div className="select-header" onClick={handleToggle} style={{borderColor:color, color:color}}>
        <span className="selected-option">
          {selectedOption ? selectedOption.label : label}
        </span>
        {selectedOption.value==="active" && <span className="dropdown-icon">
          <img src={dropdownIcon} alt="icon" />
        </span>}
        {selectedOption.value==="inactive" && <span className="dropdown-icon">
          <img src={dropdownIcon_inactive} alt="icon" />
        </span>}
        {selectedOption.value==="expired" && <span className="dropdown-icon">
          <img src={dropdownIcon_expired} alt="icon" />
        </span>}
      </div>
      {isOpen && (
        <ul className="options-list">
          {options.map((option) => (
            <li
              key={option.value}
              className="option"
              onClick={() => handleSelectOption(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default StatusSelect;
