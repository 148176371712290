
import React, { useContext, useState,useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from "react-bootstrap/Table";
import  Button  from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import PageTitle from "../components/page-title/page-title";
import Accesskey from "../components/access-key/access-key";
import Input from "../components/input/input";
import InputAccesskey from "../components/input/accesskey-input";

import { ArrowLeft,Pencil,Trash3 } from 'react-bootstrap-icons';
import { getAllConfig,generateAccessKey,createConfiguration,deleteConfiguration } from "../services/companyadmin";
import UserContext from '../store/user-context';
import CreateIcon from "../images/manage-api/create-icon.svg";


const ManageApi = () => {
    
    const navigate=useNavigate()


    const { userState: { companyId }, setUserState } = useContext(UserContext);
    const [configLimit,setConfigLimit]=useState(0)
    const [loading,setLoading]=useState(true)
    const [configurations, setConfigurations] = useState([]);
    const [UpdateUi,setUpdateUi]=useState(false)
    const [show, setShow] = useState(false);
    const [delShow, setDelShow] = useState(false);
    const [selectedForEdit,setSelectedForEdit]=useState('')
    const [selectedForDelete,setSelectedForDelete]=useState('')
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage,setSuccessMessage]=useState('')

    const handleClose = async () => {
        setAccessKey('')
        setWebsiteUrl('')
        setRedirectUrl('')
        setWebhookUrl('')
        setConfigName('')
        setShow(false)
        setDelShow(false)
        setSelectedForDelete('')
        setTimeout(() => {
            setErrorMessage('')
            setSuccessMessage('')
            setSelectedForEdit('')
        }, 100);
    };


    const handleShow = () => {
        genKey()
        setShow(true)
    }
    
    const [accessKey,setAccessKey]=useState('')
    const [configName,setConfigName]=useState('')
    const [websiteUrl,setWebsiteUrl]=useState('')
    const [redirectUrl,setRedirectUrl]=useState('')
    const [webhookUrl,setWebhookUrl]=useState('')


    


    useEffect(() => {
        const fetchData = async () => {
          try {
            setLoading(true);
            const resp = await getAllConfig(companyId);
            console.log(resp);
            if (resp.success) {
              setConfigLimit(resp.configurationLimit)
              setConfigurations(resp.configurations);
            }
            setLoading(false);
          } catch (error) {
            console.log(error.message);
            console.log('Failed to fetch configurations!');
          }
        };
    
        fetchData();
      }, [UpdateUi]);

      console.log(configurations)

    const genKey =async (id)=>{
        try{
            if(selectedForEdit.length>0)
                id=selectedForEdit;
            const resp=await generateAccessKey({_id:id})
            console.log(resp)
            if (resp.success){
                if (!resp.random)
                setUpdateUi(prev=>!prev)
                setAccessKey(resp.accessKey)
            }
        }
        catch(e){
            console.log(e)
        }
    }

    const createConfig = async ()=>{
        try{
        const data = {
            companyId: companyId,
            configName:configName,
            accessKey: accessKey,
            websiteUrl: websiteUrl,
            redirectUrl: redirectUrl,
            webhookUrl: webhookUrl,
            ...(selectedForEdit.length > 0 ? { configId: selectedForEdit }:{ configId: '' })
          };
        const resp= await createConfiguration(data)
        if (resp.success){
            console.log(resp)
            setSuccessMessage(resp.message)
            setUpdateUi(prev=>!prev)
        }
        else
            setErrorMessage(resp.message)
        setTimeout(() => {
            handleClose()
        }, 1000);
    }
        catch(e){
            console.log(e)
        }
        
    }

    const deleteConfig= async ()=>{
        try{
            const resp=await deleteConfiguration({configId:selectedForDelete,companyId:companyId})
            if(resp.success){
                setSuccessMessage(resp.message)
                setUpdateUi(prev=>!prev)
            }
            else
                setErrorMessage(resp.message)
            setTimeout(() => {
                handleClose()
            }, 1000);
        }
        catch(e){
            console.log(e)
        }
    }

    const showEdit =(id,index)=>{
        setSelectedForEdit(id)
        setAccessKey(configurations[index].accessKey)
        setConfigName(configurations[index].configName)
        setWebsiteUrl(configurations[index].websiteUrl)
        setRedirectUrl(configurations[index].redirectUrl)
        setWebhookUrl(configurations[index].webhook)
        setShow(true)
    }

    const showDelete =(id,index)=>{
        setSelectedForDelete(id)
        setAccessKey(configurations[index].accessKey)
        setWebsiteUrl(configurations[index].websiteUrl)
        setConfigName(configurations[index].configName)
        setRedirectUrl(configurations[index].redirectUrl)
        setWebhookUrl(configurations[index].webhook)
        setDelShow(true)
    }

    const handleNameChange = (event) => {
        setConfigName(event.target.value);
        if(event.target.value.length===0){
            setErrorMessage("Accesskey name cannot be empty")
        }
        else if(event.target.value.length>20){
            setErrorMessage("Accesskey name cannot be greater than 20 digits")
        }
        else{
            setErrorMessage("")
        }
    };

    return (
        <section className = "main inner-page manage-api">
            <Container fluid>
                <Row>
                    <Col xs = {12} className = "d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <ArrowLeft className='arrow-left' onClick={()=>{navigate('/')}}/>
                            <PageTitle description={`Manage API Keys | Keys Used: (${configurations.length}/${configLimit})`}/>
                        </div>
                        <Button onClick={handleShow} className = "btn btn-primary">
                            Create API Key
                        </Button>
                    </Col>
                </Row>
            </Container>
            
        {loading ? 
            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "create-api">
                            <div className = "create-icon">
                                <img src = {CreateIcon} alt = "icon"/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            :configurations.length>0?
             <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "kyc-custom-table">
                        <Table responsive className="kyc-data-table mb-0">
                        <thead>
                            <tr>
                            <th>ACCESS KEY</th>
                            <th>ACCESS KEY NAME</th>
                            <th>WEBSITE URL</th>
                            <th>REDIRECT URL</th>
                            <th>WEBHOOK URL</th>
                            <th>actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {configurations.map((config,index) => (
                            <tr key={config._id}>
                                <td><Accesskey activationKey={config.accessKey}/></td>
                                <td style={{color: !config.configName && 'grey'}}>{config.configName ? (config.configName.length > 20 ? `${config.configName.slice(0, 17)}...` : config.configName) : "*Add an Alias for API Key*"}</td>
                                <td style={{color: !config.websiteUrl && 'grey'}}>{config.websiteUrl ? (config.websiteUrl.length > 23 ? `${config.websiteUrl.slice(0, 20)}...` : config.websiteUrl) : "*Add Website Url*"}</td>
                                <td style={{color: !config.redirectUrl && 'grey'}}>{config.redirectUrl ? (config.redirectUrl.length > 23 ? `${config.redirectUrl.slice(0, 20)}...` : config.redirectUrl) : "*Add Redirect Url*"}</td>
                                <td style={{color: !config.webhook && 'grey'}}>{config.webhook ? (config.webhook.length > 23 ? `${config.webhook.slice(0, 20)}...` : config.webhook) : "*Add Webhook Url*"}</td>

                                <td>
                                    <Button onClick={()=>{genKey(config._id)}} className = "btn btn-primary-outline" style={{marginRight:'5px'}}>
                                        Regenerate Key
                                    </Button>
                                    <Button onClick={()=>{showEdit(config._id,index)}} className = "btn btn-primary-outline" style={{marginRight:'5px',paddingLeft:'12px',paddingRight:'12px'}}>
                                        <Pencil/>
                                    </Button>
                                    <Button onClick={()=>{showDelete(config._id,index)}} className = "btn btn-primary-outline" style={{paddingLeft:'12px',paddingRight:'12px'}}>
                                        <Trash3/>
                                    </Button>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
            :
            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "create-api">
                            <div className = "create-icon">
                                <img src = {CreateIcon} alt = "icon"/>
                            </div>
                            <div className = "title">
                                Create Your First API Key
                            </div>
                            <Button variant = "primary" onClick={handleShow}>
                                Create API Key
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            }


        <Modal show={show} onHide={handleClose} centered className = "create-api-modal">
            <Modal.Header closeButton>
                <Modal.Title>Create API Key</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className = "sigup-login-form create-api-form">
                    <InputAccesskey type = {'text'} placeholder = {'Access key'} currentvalue={accessKey} label = 'Access Key' change={genKey} />
                    <Input type = {'text'} value = {'Access Key Name'} val={configName} label = 'Key Name' onChange={handleNameChange}/>
                    <Input type = {'text'} value = {'Website URL'} val={websiteUrl} label = 'Website URL' onChange={(e)=>{setWebsiteUrl(e.target.value)}}/>
                    <Input type = {'text'} value = {'Redirect URL'} val={redirectUrl} label = 'Redirect URL' onChange={(e)=>{setRedirectUrl(e.target.value)}}/>
                    <Input type = {'text'} value = {'Webhook URL'} val= {webhookUrl} label = 'Webhook URL' onChange={(e)=>{setWebhookUrl(e.target.value)}}/>
                    {errorMessage && <p className="error-message mb-2 text-danger">{errorMessage}</p>}
                    {successMessage && <p className="error-message mb-2 text-success">{successMessage}</p>}
                    <div className = "form-group mb-0">
                        <Button variant = "primary" onClick={createConfig} disabled={configName.length===0 || errorMessage.length>0}>
                           {selectedForEdit.length>0?"Edit": "Create" }
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>

        <Modal show={delShow} onHide={handleClose} centered className = "create-api-modal">
            <Modal.Header closeButton>
                <Modal.Title style={{width:'100%',textAlign:'center'}} >Delete API Key</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row >
                    <h5 style={{textAlign:'center'}}>Are you sure you want to delete this API Configuration?</h5>
                </Row>
                <Form  className = "sigup-login-form create-api-form">
                    <div className = "form-group form-floating access-key-input">
                        <input  type = 'text' className = "form-control" placeholder = 'Access Key' value = {accessKey} disabled/>
                        <label>Access Key</label>
                    </div>
                    <div className = "form-group form-floating access-key-input">
                        <input  type = 'text' className = "form-control" placeholder = 'Access Key Name' value = {configName} disabled/>
                        <label>Key name</label>
                    </div>
                    <div className = "form-group form-floating access-key-input">
                        <input type = 'text' className = "form-control" placeholder = 'Website Url' value = {websiteUrl} disabled/>
                        <label>Website url</label>
                    </div>
                    <div className = "form-group form-floating access-key-input">
                        <input type = 'text' className = "form-control" placeholder = 'Redirect Url' value = {redirectUrl} disabled/>
                        <label>Redirect Url</label>
                    </div>
                    <div className = "form-group form-floating access-key-input">
                        <input type = 'text' className = "form-control" placeholder = 'Access Key' value = {webhookUrl} disabled/>
                        <label>Webhook Url</label>
                    </div>
                    {errorMessage && <p className="error-message mb-2 text-danger">{errorMessage}</p>}
                    {successMessage && <p className="error-message mb-2 text-success">{successMessage}</p>}
                </Form>
                <Row className="mx-1">
                    <Col xs={6}>
                        <Button onClick={deleteConfig} className="btn btn-danger-outline" style={{width:'100%'}}>
                            Delete
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <Button onClick={handleClose} className="btn btn-primary-outline" style={{width:'100%'}}>
                            Cancel
                        </Button>
                    </Col>
                </Row>

            </Modal.Body>
        </Modal>
        </section>
    )
}

export default ManageApi;