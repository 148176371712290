import axios from 'axios'
import config from '../config'

const baseUrl = `${config.backend}admin/`

export const registerUser = async (data) => {
    const response = await axios.post(`${baseUrl}register`, data)
    return response.data
}

export const loginUser = async (data) => {
    const response = await axios.post(`${baseUrl}login`, data)
    return response.data
}

export const checkIfUser = async(data) => {
    const response = await axios.get(`${baseUrl}checkuser?email=${data}`)
    return response.data
  }

  export const handleForgotPassword = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}forgot-password`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };


  export const verifyToken = async(data) => {
    const response = await axios.get(`${baseUrl}verifytoken?token=${data}`)
    return response.data
  }

  export const resetPassword = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}resetpassword`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const getUserDetails = async(data) => {
    const response = await axios.get(`${baseUrl}getdetails?userId=${data}`)
    return response.data
  }

  export const updateUserInfo = async (data) => {
    const response = await axios.post(`${baseUrl}updateinfo`, data)
    return response.data
  }

  export const updateUserPassword = async (data) => {
    const response = await axios.post(`${baseUrl}updatepassword`, data)
    return response.data
  }