import React,{useEffect, useState} from 'react'
import { Modal,Form,Button } from 'react-bootstrap'
import Input from '../input/input'
import Inputpassword from '../input/input-password'

import { registerUser } from '../../services/admin'

const Addcompany = ({show,handleClose,updateScreen}) => {
    

    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatpassword, setRepeatpassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage,setSuccessMessage]=useState('')
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        companyName: '',
        password: '',
        confirmPassword: '',
    });

    const handleNameChange = (event) => {
        setName(event.target.value);
        handleInputChange("name",event.target.value)
    };

    const handleCompanyChange = (event) => {
        setCompany(event.target.value);
        if(errorMessage=="A company with this name already exists"){
            setErrorMessage("")
        }
        handleInputChange("companyName",event.target.value)
        
    };

    const handleEmailChange = (event) => {
        const enteredEmail = event.target.value;
        setEmail(enteredEmail);
        validateEmail(enteredEmail);
        handleInputChange("email",event.target.value)
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        validatePasswords(event.target.value, repeatpassword);
        handleInputChange("password",event.target.value)
    };

    const handleRepeatpasswordChange = (event) => {
        setRepeatpassword(event.target.value);
        validatePasswords(password, event.target.value);
        handleInputChange("confirmPassword",event.target.value)
    }

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
                setErrorMessage('Invalid email format');
                
            } else {
                setErrorMessage('');
            }
    };

    const validateName= ()=>{
        if(errorMessage==="A company with this name already exists")
            setErrorMessage("")
    }

    const validatePasswords = (password, repeatPassword) => {
        if (password !== repeatPassword) {
            setErrorMessage("Passwords do not match");
        } else {
            setErrorMessage("");
        }
    };

    const isFormValid =  
        name !== '' &&  
        company !== '' && 
        email !== '' && 
        password !== '' && 
        repeatpassword !== '' && 
        password === repeatpassword &&
        errorMessage === ''; 

    const handleInputChange = (name,value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        };

    const clearInputValues = () => {
        setFormData({ 
            name: '',
            email: '',
            companyName: '',
            password: '',
            confirmPassword: ''})
        setName('')
        setCompany('')
        setEmail('')
        setPassword('')
        setRepeatpassword('')
    }

    const handleClose1=()=>{
        clearInputValues()
        setErrorMessage("")
        handleClose()
    }

    
    const handleSubmit = async () => {
        try
        {
            if(formData.password===formData.confirmPassword)
            {
                const resp = await registerUser(formData)
                if(resp.success){
                    clearInputValues()
                    setSuccessMessage("Company Registered Successfully ✅")
                    setTimeout(() => {
                        handleClose()
                        updateScreen()
                    }, 1000);
                }
                else{
                    console.log(resp)
                    setErrorMessage(resp.error)
                }
            }
            else{
                setErrorMessage("passwords dont match")
            }
        }
        catch(e){
            console.log(e)
        }
      };
    
  return (
            <Modal show={show} onHide={handleClose1} centered className = "create-api-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Add Company</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <Form className = "sigup-login-form create-api-form">

                        <Input type = {'text'} value = {'name'} label = 'Name' onChange={handleNameChange} autoComplete='nope'/>

                        <Input type = {'email'} value = {'email'} label = 'Email' onChange={handleEmailChange} autoComplete='nope'/>

                        <Input type = {'text'} value = {'company'} label = 'Company Name' onChange={handleCompanyChange} autoComplete='nope'/>

                        <Inputpassword type = 'password' value = {'password'} label = 'Password' onChange={handlePasswordChange} autoComplete='new-password'/>

                        <Inputpassword type = 'password' value = {'repeatpassword'} label = 'Repeat Password' onChange={handleRepeatpasswordChange} autoComplete='nope'/>

                        {errorMessage && <p className="error-message mb-2 text-danger">{errorMessage}</p>}
                        {successMessage && <p className="error-message mb-2 text-success">{successMessage}</p>}   

                            <div className = "form-group mb-0">
                                <Button onClick={handleSubmit} variant = "primary" disabled={!isFormValid}>
                                    Register
                                </Button>
                            </div>
                        </Form>
                    </Modal.Body>
            </Modal>
  )
}

export default Addcompany