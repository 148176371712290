import React, { useState, useRef, useEffect } from 'react';

import "./selectbox.css";
import dropdownIcon from "./Vector.svg";

const CustomSelect = ({ options, onSelect, label }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option) => {
    if (selectedOption !== null && selectedOption.value === option.value) {
      setSelectedOption(null);
      onSelect({ label: 'all', value: 0 });
    } else {
      setSelectedOption(option);
      onSelect(option);
    }
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={selectRef} className={`custom-select ${isOpen ? 'open' : ''}`}>
      <div className="select-header" onClick={handleToggle}>
        <span className="selected-option">
          {selectedOption===null? label : selectedOption.label}
        </span>
        <span className="dropdown-icon">
          <img src={dropdownIcon} alt="icon" />
        </span>
      </div>
      {isOpen && (
        
        <ul className="options-list">
          {/* <li className="subdivision-header">Time</li> */}
          {options.map((option) => (
            <li
              key={option.value}
              className={`option ${selectedOption !== null && selectedOption.value === option.value ? 'selected' : ''}`}
              onClick={() => handleSelectOption(option)}
            >
              {option.label}
            </li>
          ))}
          
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
