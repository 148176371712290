import React, { useState, useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Pagination } from 'react-bootstrap';
import ReportCard from '../components/kyc-report/report-card';
import ListingTable from '../components/listing-table/listing-table';
import CustomSelect from '../components/select-box/selectbox';
import Success from '../images/dashboard/KYC-success.svg';
import Uncompleted from '../images/dashboard/KYC-uncompleted.svg';
import Failed from '../images/dashboard/KYC-failed.svg';
import UserContext from '../store/user-context';
import {
  getAllCompanyKycs,
  getKycCount,
  getConfig,
  getKycDetails,
} from "../services/companyadmin";

const CompanyDashboard = () => {
  const [filteredConfig, setFilteredConfig] = useState('');
  const [loading, setLoading] = useState(true);
  const [allKyc, setAllKyc] = useState([]);
  const [searchedKyc,setSearchedKyc]=useState([])
  const [kycCounts, setKycCounts] = useState({
    total: 0,
    attempted: 0,
    left: 0,
    complete: 0,
    success: 0,
    failed: 0,
  });
  const [filter, setFilter] = useState(0);
  const [configurations, setConfigurations] = useState([]);
  const [updateUi,setUpdateUi]=useState(false)
  const [selectedConfig, setSelectedConfig] = useState('');
  const [pageNumber, setPageNumber] = useState(1); // Added pageNumber state
  const today = new Date();
  const oldestDate = new Date(1900, 0, 1); // Assuming the oldest date you want to set

  const [fromDate, setFromDate] = useState(oldestDate.toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(today.toISOString().split('T')[0]);
  const [searchQuery, setSearchQuery] = useState('');

  const { userState: { companyId }, setUserState } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const resp = await getAllCompanyKycs(companyId, filteredConfig, fromDate, toDate);
        if (resp.success) {
          console.log(resp.allKyc);
          const filteredKyc = resp.allKyc.filter((d) =>d.userId.email.toLowerCase()?.includes(searchQuery.toLowerCase()));
          setAllKyc(resp.allKyc);
          setSearchedKyc(filteredKyc)
        } else {
          throw new Error(resp.error);
        }
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        console.log('Failed to fetch Users!');
      }
    };
  
    fetchData();
  }, [filteredConfig, fromDate, toDate,updateUi]);

  useEffect(()=>{
    const filteredKyc = allKyc.filter((d) =>d.userId.email.toLowerCase()?.includes(searchQuery.toLowerCase()));
    setSearchedKyc(filteredKyc)
  },[searchQuery])
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await getKycCount(companyId, filteredConfig);
        setLoading(true);
        if (resp.success) {
          setKycCounts(resp.kycCounts);
          console.log(resp);
        } else {
          throw new Error(resp.error);
        }
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        console.log('Failed to fetch Messages!');
      }
    };

    fetchData();
  }, [filteredConfig,updateUi]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const resp = await getConfig(companyId);
        console.log(resp);
        if (resp.success) {
          setConfigurations(resp.configurations);
          setSelectedConfig(resp.configurations[0]._id);
        }
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        console.log('Failed to fetch configurations!');
      }
    };

    fetchData();
  }, []);

  const options = configurations.map((config, index) => {
    return { label: config.configName, value: config._id };
  });

  const handleSelect = (option) => {
    console.log('Selected option:', option);
    if (option.value === 0) setFilteredConfig('');
    else setFilteredConfig(option.value);
  };

  console.log(filteredConfig)

  // Update pageNumber and reset to first page when filteredConfig or filter changes
  useEffect(() => {
    setPageNumber(1);
  }, [filteredConfig, fromDate,toDate]);

  const itemsPerPage = 6; // Number of items to display per page
  const totalPageCount = Math.ceil(searchedKyc.length / itemsPerPage); // Calculate total number of pages

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const displayedKycs = searchedKyc.slice(
    (pageNumber - 1) * itemsPerPage,
    pageNumber * itemsPerPage
  ); // Get the kycs to display based on the current page number

  console.log(fromDate)
  console.log(toDate)

  const updateScreen=()=>{
    console.log("updateui",updateUi)
    setUpdateUi(prev=>!prev)
  }

  return (
    <section className="main inner-page">
      <Container fluid>
        <Row>
          <Col xs={12}>
            <div className="sort-db statistics-option">
              <label>Showing Statistics for</label>
              <CustomSelect
                options={options}
                onSelect={handleSelect}
                label="All API Keys"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="kyc-report">
              <Row className="mx-0">
                <Col md={6} lg={3}>
                  <ReportCard
                    Image={
                      <svg className="pie-svg" height="35" width="35" viewBox="0 0 20 20">
                        <circle r="10" cx="10" cy="10" fill="white" />
                        <circle
                          r="5"
                          cx="10"
                          cy="10"
                          fill="transparent"
                          stroke="#4080FF"
                          strokeWidth="10"
                          strokeDasharray={`calc(${kycCounts.attempted} * 31.4 / ${kycCounts.total}) 31.4`}
                          transform="rotate(-90) translate(-20)"
                        />
                      </svg>
                    }
                    reportTitle={'KYC Consumed'}
                    result={`${kycCounts.attempted} / ${kycCounts.total}`}
                  />
                </Col>
                <Col md={6} lg={3}>
                  <ReportCard
                    Image={<img src={Success} alt="icon" />}
                    reportTitle={'KYC Successful'}
                    result={kycCounts.success}
                  />
                </Col>
                <Col md={6} lg={3}>
                  <ReportCard
                    Image={<img src={Uncompleted} alt="icon" />}
                    reportTitle={'KYC Incompleted'}
                    result={kycCounts.attempted - kycCounts.complete}
                  />
                </Col>
                <Col md={6} lg={3}>
                  <ReportCard
                    Image={<img src={Failed} alt="icon" />}
                    reportTitle={'KYC Failed'}
                    result={kycCounts.failed}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col xs={12}>
            <div className="kyc-table">
              <ListingTable
                data={displayedKycs} // Pass the sliced kycs to ListingTable
                loading={loading}
                getKycDetails={getKycDetails}
                setFilter={setFilter}
                setFilteredConfig={setFilteredConfig}
                configurations={configurations}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                setFromDate={setFromDate}
                setToDate={setToDate}
                setSearchQuery={setSearchQuery}
                updateScreen={updateScreen}
                updateUi={updateUi}
              />
            </div>
          </Col>
        </Row>
        {searchedKyc.length > 0 &&
        <Row className="justify-content-center">
            <Col xs={12}>
                <Pagination className="pagination">
                <Pagination.First
                    disabled={pageNumber === 1}
                    onClick={() => handlePageChange(1)}
                />
                <Pagination.Prev
                    disabled={pageNumber === 1}
                    onClick={() => handlePageChange(pageNumber - 1)}
                />
                {pageNumber > 2 && (
                    <>
                    <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                    {pageNumber > 3 && <Pagination.Ellipsis disabled />}
                    </>
                )}
                {pageNumber > 1 && (
                    <Pagination.Item onClick={() => handlePageChange(pageNumber - 1)}>
                    {pageNumber - 1}
                    </Pagination.Item>
                )}
                <Pagination.Item active>{pageNumber}</Pagination.Item>
                {pageNumber < totalPageCount && (
                    <Pagination.Item onClick={() => handlePageChange(pageNumber + 1)}>
                    {pageNumber + 1}
                    </Pagination.Item>
                )}
                {pageNumber < totalPageCount - 1 && (
                    <>
                    {pageNumber < totalPageCount - 2 && <Pagination.Ellipsis disabled />}
                    <Pagination.Item onClick={() => handlePageChange(totalPageCount)}>
                        {totalPageCount}
                    </Pagination.Item>
                    </>
                )}
                <Pagination.Next
                    disabled={pageNumber === totalPageCount}
                    onClick={() => handlePageChange(pageNumber + 1)}
                />
                <Pagination.Last
                    disabled={pageNumber === totalPageCount}
                    onClick={() => handlePageChange(totalPageCount)}
                />
                </Pagination>
            </Col>
        </Row>}
      </Container>
    </section>
  );
};

export default CompanyDashboard;
