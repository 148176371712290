import React, { useState,useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLocation,useNavigate,Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import Inputpassword from '../components/input/input-password';

import { verifyToken,resetPassword } from '../services/admin';

const Resetpassword = ()=>{

    const [token,setToken]=useState('')
    const [userId,setUserId]=useState('')
    const [tokenStatus,setTokenStatus]=useState('')
    const [passwordUpdated,setPasswordUpdate]=useState(false)
    const [password, setPassword] = useState('');
    const [repeatpassword, setRepeatpassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/forgot-password');
    };

    useEffect(()=>{
        setToken(searchParams.get('token'))
    },[])

    const verify=async()=>{
        const res=await verifyToken(token)
        if(res.token=='valid'){
            setUserId(res.userId)
        }
        setTokenStatus(res.token)
    }

    useEffect(()=>{
        if(token && token.length>0){
            verify()
        }
    },[token])

    const reset=async ()=>{
        const res1=await resetPassword({userId,password})
        if(res1.updated){
            setPasswordUpdate(true)
        }
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        validatePasswords(event.target.value, repeatpassword);
    };

    const handleRepeatpasswordChange = (event) => {
        setRepeatpassword(event.target.value);
        validatePasswords(password, event.target.value);
    }

    const validatePasswords = (password, repeatPassword) => {
        if (password !== repeatPassword) {
            setErrorMessage("Passwords do not match");
        } else {
            setErrorMessage("");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
      
        if (password !== repeatpassword) {
          setErrorMessage("Passwords do not match");
          return;
        }
        if (password=="") {
            setErrorMessage("Passwords cannot be empty");
            return;
        }
        if (password.length<8) {
            setErrorMessage("Passwords cannot be less than 8 digits");
            return;
          }
        reset()
        
      };

    const isFormValid = password !== '' && 
    repeatpassword !== '' && 
    password === repeatpassword &&
    errorMessage === '' &&
    passwordUpdated===false;
    
    console.log(userId)

    return(

        <section className = "main form-main">
            <Container>
                <Row>
                    <Col xs = "12">

                    <div className = "signup-content position-relative d-flex align-items-center justify-content-center">

                            <div className = "sigup-login-form login-form">
                                <h1>
                                    Reset your password
                                </h1>

                                {tokenStatus=="valid"&& <>
                                <Inputpassword type = 'password' value = {'password'} label = 'Password' onChange={handlePasswordChange}/>

                                <Inputpassword type = 'password' value = {'repeatpassword'} label = 'Repeat Password' onChange={handleRepeatpasswordChange}/>
                                
                                {errorMessage && !passwordUpdated && <p className="error-message mb-2 text-danger">{errorMessage}</p>}
                                {passwordUpdated===true && <p className="error-message mb-2 text-success">Password updated Successfully ✔</p>}
                                <div className = "form-group">
                                        <Button type = "submit" variant = "primary" disabled={!isFormValid} onClick={handleSubmit}>
                                            Update
                                        </Button>
                                    </div>
                                </>
                               }
                               {tokenStatus=="expired" &&(
                                    <>
                                        <p className="error-message mb-3 text-center text-danger">Your Link is expired, Click on Reset Password to generate a new link.</p>
                                        <div className = "form-group">
                                            <Button onClick={handleClick}>
                                                Reset Password
                                            </Button>
                                        </div>
                                    </>
                                )}
                                {tokenStatus=="invalid" &&(
                                    <>
                                        <p className="error-message mb-3 text-center text-danger">Your Link is invalid, Click on Reset Password to generate a valid password reset link.</p>
                                        <div className = "form-group">
                                            <Button onClick={handleClick}>
                                                Reset Password
                                            </Button>
                                        </div>
                                    </>
                                )}
                                

                                
                                <div className = "form-group">
                                    <p className = "mb-0 text-center f-pra">
                                        <Link to = "/login">Back to Login</Link>
                                    </p>
                                </div>

                            </div>
                    </div>

                    </Col>
                </Row>
            </Container>
        </section>
        
    );
}

export default Resetpassword;
