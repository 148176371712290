import React from "react";
import "./user-status.css";
const EmailStatus = ({className, email}) => {
    
    return (
       <div className = {className}>
            <div className = "email-status">
                <div className = "status-dot">
                </div>
                <div className = "status-email">
                    {email}
                </div>
            </div>
       </div>
    );
}
export default EmailStatus;