
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from 'react-bootstrap/Button';

import { useContext } from 'react';
import Kyc_logo from "../../images/logo/KYCLogo.svg";
import Language_iocn from "../../images/header/language-icon.svg";
import company_logo from "../../images/account/personicon.png";
import User_icon from "../../images/header/user.svg";
import Setting_icon from "../../images/header/setting.svg";
import Logout_icon from "../../images/header/logout.svg";
import Dropdown_icon from "../../images/header/menu-dp.svg"
import UserContext from '../../store/user-context';
import { getUserDetails } from '../../services/admin';

import "./navbar.css";

const Navbarmain = ({ logOutBtnHandler }) => {
    const { userState: { isAuth, userId, updateUser }, setUserState } = useContext(UserContext)
    console.log(isAuth)
    const [userData, setUserData] = useState({})
    const navigate = useNavigate()
    const [isActive, setIsActive] = useState(false);
    const buttonRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Check if the click event occurred outside the button
            if (buttonRef.current && !buttonRef.current.contains(event.target)) {
                setIsActive(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener('click', handleOutsideClick);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    },
        []);

    const toggleButton = () => {
        setIsActive(!isActive);
    };

    const handleClick = () => {
        toggleButton();
    };

    const location = useLocation();
    const { pathname } = location;

    const showLanguageButton = !isAuth;
    const showUserDropdown = isAuth;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resp = await getUserDetails(userId)
                if (resp.success) {
                    console.log(resp.data)
                    setUserData(resp.data)
                    if (updateUser) {
                        setUserState(prevState => ({
                            ...prevState,
                            updateUser: false
                        }));
                    }
                }
                else
                    throw new Error(resp.error)
            } catch (error) {
                console.log(error)
            }
        }

        if (isAuth) {
            fetchData()
        }
    }, [isAuth, updateUser])

    return (

        <Navbar fixed="top" className="navbar-main">
            <Container fluid>
                <Navbar.Brand onClick={() => { navigate('/') }} style={{ cursor: 'pointer' }} className="border-0">
                    <img src={Kyc_logo} alt="logo" />
                </Navbar.Brand>
                <Navbar>
                    <Nav className="ms-auto">

                        {showUserDropdown && (
                            <div ref={buttonRef} className={isActive ? (!userData.companyName ? 'admin active menu-dropdown' : 'active menu-dropdown') : 'menu-dropdown'}
                                onClick={handleClick}>
                                {userData.companyName ? <Button variant="toggle-m">
                                    <div className="logo-img">
                                        <img src={company_logo} alt="logo" />
                                    </div>
                                    <span className="company-name">
                                        {userData.companyName}
                                    </span>
                                    <span className="dp-icon">
                                        <img src={Dropdown_icon} alt="icon" />
                                    </span>
                                </Button>
                                    :
                                    <Button variant="toggle-m">
                                        <div className="logo-img">
                                            <div className="super-admin-icon">
                                                S
                                            </div>
                                        </div>
                                        <span className="company-name">
                                            Super Admin
                                        </span>
                                        <span className="dp-icon">
                                            <img src={Dropdown_icon} alt="icon" />
                                        </span>
                                    </Button>}
                                <div className="dd-menu-list">
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <Link to="/my-account">
                                                <div className="link-icon">
                                                    <img src={User_icon} alt="icon" />
                                                </div>
                                                <span>My Account</span>
                                            </Link>
                                        </li>
                                        {userData.companyName && <li className="nav-item">
                                            <Link to="/manage-api">
                                                <div className="link-icon">
                                                    <img src={Setting_icon} alt="icon" />
                                                </div>
                                                <span>Manage API Keys</span>
                                            </Link>
                                        </li>}
                                        <li className="nav-item" onClick={() => { logOutBtnHandler() }}>
                                            <Link>
                                                <div className="link-icon">
                                                    <img src={Logout_icon} alt="icon" />
                                                </div>
                                                <span>Sign out</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )}

                        {showLanguageButton && <Button variant="language">
                            <img src={Language_iocn} alt="logo" />English
                        </Button>}

                    </Nav>
                </Navbar>
            </Container>
        </Navbar>

    );
}

export default Navbarmain;