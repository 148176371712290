// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
:root {
    --page-title-color: #464B53;
}

.page-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 15px;
    color: #464B53;
}`, "",{"version":3,"sources":["webpack://./src/components/page-title/page-title.css"],"names":[],"mappings":";AACA;IACI,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":["\n:root {\n    --page-title-color: #464B53;\n}\n\n.page-title {\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 23px;\n    margin-bottom: 15px;\n    color: #464B53;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
