import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Pagination } from 'react-bootstrap';
import PageTitle from '../components/page-title/page-title';
import CompanyTable from '../components/admin-component/company-table';
import Addcompany from '../components/admin-component/add-company';
import { getAllUsers } from '../services/superadmin';
import { getKycCount } from '../services/companyadmin';

const ManageCompany = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [updateUi, setUpdateUi] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const resp = await getAllUsers();
        if (resp.success) {
          setAllUsers(resp.users);
        } else {
          throw new Error(resp.error);
        }
      } catch (error) {
        console.log(error.message);
        console.log('Failed to fetch Users!');
      }
      setLoading(false);
    };

    fetchData();
  }, [updateUi]);

  const getCompanyData = async (_id) => {

    try {
      const configId = '';
      console.log(_id);
      const resp = await getKycCount(_id, configId);
      if (resp.success) {
        console.log(resp);
        return resp;
      } else {
        throw new Error(resp.error);
      }
    } catch (error) {
      console.log(error.message);
      console.log('Failed to fetch Messages!');
    }

  };

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const totalPageCount = Math.ceil(allUsers.length / itemsPerPage); // Calculate total number of pages

  const displayedUsers = allUsers.slice(
    (pageNumber - 1) * itemsPerPage,
    pageNumber * itemsPerPage
  ); // Get the users to display based on the current page number

  const updateScreen=()=>{
    setUpdateUi(prev=>!prev)
  }

  return (
    <section className="main inner-page admin-section">
      <Container fluid>
        <Row>
          <Col xs={12} className="d-flex align-items-center justify-content-between">
            <PageTitle description={'Manage Companies'} />
            <Button variant="primary" onClick={handleShow}>
              Add Company
            </Button>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col xs={12}>
            <div className="kyc-table">
              <CompanyTable
                data={displayedUsers}
                getCompanyData={getCompanyData}
                loading={loading}
                updateScreen={updateScreen}
                setLoading={setLoading}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
              />
            </div>
          </Col>
        </Row>
        {allUsers.length > 0 && (
          <Row className="justify-content-center">
            <Col xs={12}>
            <Pagination className="pagination">
                <Pagination.First
                    disabled={pageNumber === 1}
                    onClick={() => handlePageChange(1)}
                />
                <Pagination.Prev
                    disabled={pageNumber === 1}
                    onClick={() => handlePageChange(pageNumber - 1)}
                />
                {pageNumber > 2 && (
                    <>
                    <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                    {pageNumber > 3 && <Pagination.Ellipsis disabled />}
                    </>
                )}
                {pageNumber > 1 && (
                    <Pagination.Item onClick={() => handlePageChange(pageNumber - 1)}>
                    {pageNumber - 1}
                    </Pagination.Item>
                )}
                <Pagination.Item active>{pageNumber}</Pagination.Item>
                {pageNumber < totalPageCount && (
                    <Pagination.Item onClick={() => handlePageChange(pageNumber + 1)}>
                    {pageNumber + 1}
                    </Pagination.Item>
                )}
                {pageNumber < totalPageCount - 1 && (
                    <>
                    {pageNumber < totalPageCount - 2 && <Pagination.Ellipsis disabled />}
                    <Pagination.Item onClick={() => handlePageChange(totalPageCount)}>
                        {totalPageCount}
                    </Pagination.Item>
                    </>
                )}
                <Pagination.Next
                    disabled={pageNumber === totalPageCount}
                    onClick={() => handlePageChange(pageNumber + 1)}
                />
                <Pagination.Last
                    disabled={pageNumber === totalPageCount}
                    onClick={() => handlePageChange(totalPageCount)}
                />
                </Pagination>
            </Col>
          </Row>
        )}
      </Container>

      <Addcompany show={show} handleClose={handleClose} updateScreen={setUpdateUi} />
    </section>
  );
};

export default ManageCompany;
