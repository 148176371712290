
import React from "react";

import "./user-status.css";

const StatusComp = ({ admin=false,status }) => {

    let statusText = '';

    if (status === 'success') {
        admin? statusText = 'Active': statusText = 'Success';
    } else if (status === 'failed') {
        admin? statusText='Expired' :statusText = 'Failed';
    } else if (status === 'uncomplete') {
        admin? statusText='Inactive' : statusText = 'Incomplete';
    }
    
    return (
        <div className={`status-check ${status}`}>
            <span className="status-icon"></span>
            <span className="status-title">{statusText}</span>
        </div>
    );
}

export default StatusComp;