import React, { useState, useRef, useEffect } from 'react';
import "./selectbox.css";
import dropdownIcon from "./Vector.svg";

const CustomSelect = ({ setFromDate, setToDate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState('');
  const [selectedToDate, setSelectedToDate] = useState('');
  const selectRef = useRef(null);

  const clearDate=()=>{
    const today = new Date();
    const oldestDate = new Date(1900, 0, 1);
    setFromDate(oldestDate.toISOString().split('T')[0])
    setToDate((today.toISOString().split('T')[0]))
    setSelectedFromDate('')
    setSelectedToDate('')
  }

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option) => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleFromDateChange = (event) => {
    const selectedDate = event.target.value;
    setSelectedFromDate(selectedDate);
    setFromDate(selectedDate);
  };

  const handleToDateChange = (event) => {
    const selectedDate = event.target.value;
    setSelectedToDate(selectedDate);
    setToDate(selectedDate);
  };

  console.log(selectedFromDate,"meh")

  return (
    <div ref={selectRef} className={`custom-select ${isOpen ? 'open' : ''}`}>
      <div className="select-header" onClick={handleToggle}>
      <span className="selected-option">
        Filter By{selectedFromDate || selectedToDate ? ': Date' : ''}
        {selectedFromDate && selectedToDate ? ` (From,To)` : selectedFromDate ? ` (From)` : selectedToDate ? ` (To)` : ''}
        </span>
        <span className="dropdown-icon">
          <img src={dropdownIcon} alt="icon" />
        </span>
      </div>
      {isOpen && (
        <ul className="options-list">
          <li className="subdivision-header" style={{display:'flex',justifyContent:'space-between'}}><span>Date:</span>  {(selectedFromDate.length!==0 || selectedToDate.length!==0) && <span style={{color:'#4080FF',cursor:'pointer'}} onClick={clearDate}>Clear</span>}</li>
          <li className="date-input" style={{ padding: '0',paddingTop:'5px' }}>
            <span style={{marginLeft:'15px'}}>From:</span>
            <input

              type="date"
              value={selectedFromDate}
              onChange={handleFromDateChange}
              style={{ width: '160px',marginTop:'5px',cursor:'pointer' }}
            />
          </li>
          <li className="date-input" style={{ padding: '0',paddingTop:'5px' }}>
          <span style={{marginLeft:'15px'}}>To:</span>
            <input
              type="date"
              value={selectedToDate}
              onChange={handleToDateChange}
              style={{ width: '160px',marginTop:'5px',cursor:'pointer' }}
            />
          </li>
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
