import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';

import Input from '../components/input/input';
import { checkIfUser,handleForgotPassword } from '../services/admin';


const Forgotpassword = ()=>{

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [userExists,setUserExists]=useState(null)
    const [emailSent,setEmailSent]=useState(false)
    const [sending,setSending]=useState(false)

    const handleEmailChange = (event) => {
        const enteredEmail = event.target.value;
        setEmail(enteredEmail);
        validateEmail(enteredEmail);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
                setErrorMessage('Invalid email format');
            } else {
                setErrorMessage('');
            }
    };

    const isFormValid = email !== '' &&
    errorMessage === '' && 
    emailSent===false &&
    sending===false;

    const checkUser= async ()=>{
        setSending(true)
        const res=await checkIfUser(email)
        setUserExists(res.userFound)
        if(!res.userFound)
            setErrorMessage('User with this email does not exist')
        if(res.userFound){
            const res1=await handleForgotPassword(email)
            if(res1.emailSent){
                setEmailSent(true)
            }
        }
        setSending(false)
    }

    return(

        <section className = "main form-main">
            <Container>
                <Row>
                    <Col xs = "12">

                    <div className = "signup-content position-relative d-flex align-items-center justify-content-center">
                            <div className = "sigup-login-form login-form">
                                <h1>
                                    Forgot Password?
                                </h1>

                                <div className = "form-group">
                                    <p className = "mb-0 text-center">
                                        Enter your registered email to get password recovery link
                                    </p>
                                </div>

                                <Input type = {'email'} value = {'email@example.com'} label = {'Email'} onChange={handleEmailChange}/>
                               
                               {errorMessage && <p className="error-message mb-2 text-danger">{errorMessage}</p>}
                               {emailSent && <p className="error-message mb-2 text-success">An email has been sent to you with a link to reset your password ✔</p>}

                                <div className = "form-group">
                                    <Button type = "submit" variant = "primary" onClick={checkUser} disabled={!isFormValid}>
                                        Submit
                                    </Button>
                                </div>

                                <div className = "form-group">
                                    <p className = "text-center">
                                        <Link to = "/login">Back to Login</Link>.
                                    </p>
                                </div>
                            </div>
                    </div>

                    </Col>
                </Row>
            </Container>
        </section>
        
    );
}

export default Forgotpassword;
