// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/login-signup/eye-icon.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/login-signup/show-password.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.access-key {
    display: flex;
    align-items: center;
    gap: 5px;
}

.access-key .key-text {
    position: relative;
    top: 3px;
    padding: 2px;
    width: 170px;
    box-sizing: border-box;
}

.manage-api .kyc-custom-table .table tr td .show-btn {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    padding: 2px;
    width: 20px;
    height: 20px;
    background-size: 100%;
}

.manage-api .kyc-custom-table .table tr td .show-btn.active {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}`, "",{"version":3,"sources":["webpack://./src/components/access-key/access-key.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,YAAY;IACZ,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yDAA+D;IAC/D,eAAe;IACf,4BAA4B;IAC5B,2BAA2B;IAC3B,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,yDAAoE;AACxE","sourcesContent":["\n\n.access-key {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n}\n\n.access-key .key-text {\n    position: relative;\n    top: 3px;\n    padding: 2px;\n    width: 170px;\n    box-sizing: border-box;\n}\n\n.manage-api .kyc-custom-table .table tr td .show-btn {\n    width: 15px;\n    height: 15px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-image: url('../../images/login-signup/eye-icon.svg');\n    cursor: pointer;\n    background-repeat: no-repeat;\n    background-position: center;\n    padding: 2px;\n    width: 20px;\n    height: 20px;\n    background-size: 100%;\n}\n\n.manage-api .kyc-custom-table .table tr td .show-btn.active {\n    background-image: url('../../images/login-signup/show-password.svg');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
