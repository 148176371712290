
import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

import PageTitle from "../components/page-title/page-title";
import Input from "../components/input/input";
import Inputpassword from "../components/input/input-password";
import Exs_logo from "../images/account/personicon.png";


import GeneralSettings from "../components/my-account/GeneralSettings";
import SelectSettings from "../components/my-account/SelectSettings";
import PasswordSettings from "../components/my-account/PasswordSettings";
import { useState } from "react";
import { ArrowLeft } from 'react-bootstrap-icons';


const MyAccount = () => {
    const [selectedSettings,setSelectedSettings]=useState(null)
    const navigate=useNavigate()


    return (
        <section className = "main inner-page account-page">
            <Container fluid className = "mb-1 mb-md-4 px-2 px-sm-0">
                <Row>
                    <Col xs = {12} className = "px-0">
                    <div className="d-flex align-items-center">
                    {selectedSettings===null && <ArrowLeft className='arrow-left' onClick={()=>{navigate('/')}} style={{marginBottom:'15px'}}/>}
                        <PageTitle description={'My Account'}/>
                    </div>
                    </Col>
                </Row>
            </Container>

            <Container fluid  className = "px-2 px-sm-0">
                <Row className = "my-account-sec">
                    <Col md = {5} className = "px-0 d-none d-md-block">
                        <div className = "logo-side h-100">
                            <div className = "company-logo">
                                <img src = {Exs_logo} alt = "logo"/>
                            </div>
                        </div>
                    </Col>
                    <Col md = {7} className = "px-0">
                       {selectedSettings==="general" ? <GeneralSettings setSelectedSettings={setSelectedSettings}/>: 
                       selectedSettings==="password"? <PasswordSettings setSelectedSettings={setSelectedSettings}/>: <SelectSettings setSelectedSettings={setSelectedSettings}/>}
                    </Col>
                </Row>
            </Container>

        </section>
    )
}

export default MyAccount;