
import React, { useState } from 'react';

import "./input.css";

const Inputpassword = ({label,value, onChange,autoComplete='on'}) => {

    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!isPasswordVisible);
    };

    const togglepassword = () => {
        setIsActive(!isActive);
    }

    const handleClick = () => {
        togglepassword();
        togglePasswordVisibility();
    }

    const inputType = isPasswordVisible ? "text" : "password";

        return(
            <>
                <div className = "form-group form-floating password-input">
                    <input type = {inputType} className = "form-control" placeholder = {value} onChange={onChange} autoComplete={autoComplete}/>
                    <label>{label}</label>
                    <span className = {isActive ? 'active show-btn' : 'show-btn'} onClick={handleClick}>
                    </span>
                </div>
            </>
        );
}

export default Inputpassword;