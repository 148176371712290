import { Collapse } from 'react-bootstrap';
import React, { useEffect, useState } from "react";


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import CustomSelect from "../select-box/selectbox1";
import Spinner from 'react-bootstrap/Spinner';


import StatusComp from "../user-status/user-status";
import UserHeader from "../user-details/details-header";
import UserDetails from "../user-details/user-details";
import UserPhoto from "../user-details/user-photo";
import IdPhoto from "../user-details/id-photo";
import PageTitle from "../page-title/page-title";

import detailBg from "../../images/dashboard/detail-db-icon.svg";

import { Pencil } from 'react-bootstrap-icons';

import "../user-details/user-details.css";

import EditStatus from "./EditStatus";


const ListingTable = ({data,loading,updateScreen, getKycDetails,updateUi,pageNumber,itemsPerPage,setFromDate,setToDate,setSearchQuery }) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [kycDetails, setKycDetails] = useState({});
  const [kycToShow,setKycToShow]=useState('')
  const [showMessage, setShowMessage] = useState(false);
  const [show,setShow]=useState(false)
  

  const handleClose=()=>{
    setShow(false)
  }

  useEffect(() => {
    setExpandedRow(null);
  }, [pageNumber]);

  useEffect(() => {
    if(!loading && data.length===0)
    {const timeout = setTimeout(() => {
      setShowMessage(true);
    }, 500);

    return () => clearTimeout(timeout); }// Cleanup function to clear the timeout on unmount
  }, [loading,data]);

  function convertDate(dateString) {
    const date = new Date(dateString);

    // Format the date
    const formattedDate = date.toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });

    // Format the time
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    // Combine the formatted date and time
    const formattedDateTime = `${formattedDate} - ${formattedTime}`;

    return formattedDateTime;
  }

  const toggleCollapse = async (rowIndex, id) => {
    console.log(rowIndex,expandedRow)
    if (expandedRow === rowIndex) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowIndex);
      setKycToShow(id);
      console.log('Expanded row id:', id);
    }
  };

  useEffect(()=>{
    const getData=async ()=>{
        try {
          const resp = await getKycDetails(kycToShow);
          setKycDetails(resp);
        } catch (e) {
          console.log(e);
        }
    }

    if(kycToShow.length>0)
      getData()
  },[kycToShow,updateUi])



  return (
    <>
      <Row className="align-items-center">
        <Col md={4}>
          <PageTitle description={'KYC Listing'} mb-0 />
        </Col>
        <Col md={8}>
          <div className="table-sort d-flex justify-content-md-end">
            <input className="form-control" onChange={(e)=>{setSearchQuery(e.target.value)}} placeholder="Search..." />
            <div className="sort-db">
              <label>Filter By</label>
              <CustomSelect setFromDate={setFromDate} setToDate={setToDate}/>
            </div>
          </div>
        </Col>
      </Row>

      <div className="kyc-custom-table">
      <Table responsive className="kyc-data-table mb-0">
        <thead>
            <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Created AT</th>
            <th>Status</th>
            <th>Details</th>
            </tr>
        </thead>
        {loading ? (
        <tbody>
          <tr style={{ textAlign: 'center', margin: '50px' }}>
            <td style={{ fontSize: '15px', color: '#A1ABC5' }} colSpan={12}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            </td>
          </tr>
        </tbody>
        ) :(data.length > 0) ? (
            <tbody>
            {data
                .map((item, index) => (
                <React.Fragment key={item._id}>
                    <tr
                    className={expandedRow === index ? 'active' : ''}
                    onClick={() => toggleCollapse(index, item._id)}
                    aria-controls={`detail${index}`}
                    aria-expanded={expandedRow === index}
                    >
                    <td>{ ((pageNumber-1)*itemsPerPage)+ (index + 1)}</td>
                    <td>{item.userId.email}</td>
                    <td>{convertDate(item.createdAt)}</td>
                    <td>
                        <div className="status-check">
                        <StatusComp
                            status={
                            item.kycCompleted && item.kycStatus
                                ? 'success'
                                : item.kycCompleted && !item.kycStatus
                                ? 'failed'
                                : 'uncomplete'
                            }
                        />
                        </div>
                    </td>
                    <td>
                        <div className="detail-btn">
                        <img src={detailBg} alt="db-icon" />
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <td className="hidden-row py-0" colSpan={5}>
                      <Collapse in={expandedRow === index}>
                        <div id={`detail${index}`}>
                            
                            {kycDetails.userId && (
                              
                            <Row className="py-3">
                              
                                <Col xs={8} md={6} lg={4} className="mb-3 mb-lg-0">
                                <div className = "detail-title" style={{display:'flex',gap:'10px'}}>
                                  KYC Info  <Pencil className='edit-pencil' onClick={()=>{setShow(true)}}/>
                                </div>
                                <div className="kyc-info">
                                    <UserDetails
                                    title={'Email:'}
                                    titleDescription={kycDetails.userId.email}
                                    />
                                    <UserDetails
                                    title={'Country:'}
                                    titleDescription={kycDetails.country}
                                    />
                                    <UserDetails
                                    title={'Doc Type:'}
                                    titleDescription={
                                        kycDetails.documentType === 'ID'
                                        ? 'Identity Card'
                                        : 'Passport'
                                    }
                                    />
                                    <UserDetails
                                    title={'Kyc Date:'}
                                    titleDescription={convertDate(
                                        kycDetails.createdAt
                                    )}
                                    />
                                    <UserDetails
                                    title={'KYC Status:'}
                                    titleDescription={
                                        kycDetails.kycCompleted
                                        ? kycDetails.kycStatus
                                            ? 'Successful'
                                            : 'Failed'
                                        : 'Incomplete'
                                    }
                                    />
                                    <UserDetails
                                    title={'Comments:'}
                                    titleDescription={kycDetails.kycMessage}
                                    />
                                </div>
                                </Col>
                                <Col xs={4} md={6} lg={2} className="mb-3 mb-lg-0">
                                <UserHeader titleHeading={'Photo'} />
                                {kycDetails.selfieId && <UserPhoto photo={kycDetails.selfieId.fileData} />}
                                </Col>
                                {kycDetails.documentType === 'ID' ? (
                                <>
                                    <Col xs={6} md={6} lg={3} className="mb-3 mb-md-0">
                                    <UserHeader
                                        titleHeading={'ID Card'}
                                        spantext={'Front'}
                                    />
                                    {kycDetails.documentId && <IdPhoto
                                        idphoto={kycDetails.documentId.fileData}
                                    />}
                                    </Col>
                                    <Col xs={6} md={6} lg={3} className="mb-3 mb-md-0">
                                    <UserHeader
                                        titleHeading={'ID Card'}
                                        spantext={'Back'}
                                    />
                                     
                                    {kycDetails.documentId2 && <IdPhoto
                                        idphoto={kycDetails.documentId2.fileData}
                                    />}
                                    </Col>
                                </>
                                ) : (
                                <Col xs={6} md={6} lg={3} className="mb-3 mb-md-0">
                                    <UserHeader
                                    titleHeading={'Passport'}
                                    spantext={'Front'}
                                    />
                                    {kycDetails.documentId && <IdPhoto
                                        idphoto={kycDetails.documentId.fileData}
                                    />}
                                </Col>
                                )}
                            </Row>
                            )}
                        </div>
                        </Collapse>
                    </td>
                    </tr>
                </React.Fragment>
                ))}
            </tbody>
        ) : (
          showMessage? 
            <tbody>
                <tr style={{textAlign:'center',margin:'50px'}}>
                    <td style={{fontSize:'30px',color:'black',fontWeight:'300'}} colSpan={12}>No Results Found</td>
                </tr>
            </tbody>:
            <tbody>
            <tr style={{ textAlign: 'center', margin: '50px' }}>
            <td style={{ fontSize: '15px', color: '#A1ABC5' }} colSpan={12}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            </td>
          </tr>
          </tbody>
        )}
        </Table>
      </div>
      {kycToShow.length>0 && <EditStatus show={show} handleClose={handleClose} kycDetails={kycDetails} updateScreen={updateScreen}/>}
    </>
  );
};

export default ListingTable;
