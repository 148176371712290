import React, { useState,useEffect,useContext } from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import PageTitle from "../page-title/page-title";
import Input from "../input/input";
import Inputpassword from "../input/input-password";
import Exs_logo from "../../images/account/exs-logo.png";

import { ArrowLeft } from 'react-bootstrap-icons';
import { getUserDetails } from '../../services/admin';
import UserContext from '../../store/user-context';

import './settings.css'

import { updateUserPassword } from '../../services/admin';

const PasswordSettings = ({setSelectedSettings}) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage,setSuccessMessage]=useState('');

  const { userState:{userId}, setUserState } = useContext(UserContext)

  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
    validatePassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
    const bool=validatePassword(event.target.value);
    if(bool)
      validateNewPasswords(event.target.value,confirmNewPassword);
  };

  const handleConfirmNewPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value);
    const bool=validatePassword(event.target.value);
    if(bool)
      validateNewPasswords(event.target.value,newPassword);
  };

  const validatePassword = (password) => {
    if (password.length===0) {
        setErrorMessage("Password cannot be empty");
        return false
    } else {
        setErrorMessage("");
        return true
    }
  };

  const validateNewPasswords = (password, repeatPassword) => {
    if (password !== repeatPassword) {
        setErrorMessage("Passwords do not match");
    } else {
        setErrorMessage("");
    }
  };

  const isFormValid =  
        oldPassword !== '' && 
        newPassword !== '' && 
        confirmNewPassword !== '' && 
        newPassword===confirmNewPassword
        ;

  const handleSubmit=async ()=>{
    const formData={
      userId:userId,
      oldPassword:oldPassword,
      newPassword:newPassword
    }

    const resp=await updateUserPassword(formData)
    console.log(resp)
    if(resp.success){
      setSuccessMessage(resp.message)
      setTimeout(() => {
        setSelectedSettings(null)
      }, 1000);
    }
    else{
      setErrorMessage(resp.message)
    }

  }

  return (
    <div className = "account-form-side sigup-login-form h-100">
        <div className = "form-group mb-4">
        <div className="d-flex align-items-center">
            <ArrowLeft className='arrow-left' onClick={()=>{setSelectedSettings(null)}}/>
            <div className = "page-title1">
              Password management
            </div>
        </div>
        </div>
        <div className = "password-title">
                Current Password
        </div>
        <Inputpassword type = 'password' value = {'password'} label = 'Current password' onChange={handleOldPasswordChange}/>
        <div className = "password-title">
                New Password
        </div>
        <Inputpassword type = 'password' value = {'password'} label = 'New password' onChange={handleNewPasswordChange}/>
        <Inputpassword type = 'password' value = {'password'} label = 'Confirm new password' onChange={handleConfirmNewPasswordChange}/>
        {errorMessage && <p className="error-message mb-2 text-danger">{errorMessage}</p>}
        {successMessage && <p className="error-message mb-2 text-success">{successMessage}</p>}

        <div className = "form-group" >
            <Button type = "submit" variant = "primary" disabled={!isFormValid} onClick={handleSubmit}>
                Save Change
            </Button>
        </div>
    </div>
  )
}

export default PasswordSettings