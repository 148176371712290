
import React from "react";

import "./user-details.css";

const IdPhoto = ({idphoto}) => {
    return (
        <>
            <div className = "id-photo">
                <img src = {idphoto} alt = "user"/>
            </div>
        </>
    )
}

export default IdPhoto;