// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.navbar-main {
    background-color: #fff;
}

.navbar-main .btn-language {
    background: #EFF3FC;
    color: #464B53;
}

.navbar-main .btn-language img {
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/navbar.css"],"names":[],"mappings":";;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["\n\n.navbar-main {\n    background-color: #fff;\n}\n\n.navbar-main .btn-language {\n    background: #EFF3FC;\n    color: #464B53;\n}\n\n.navbar-main .btn-language img {\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
